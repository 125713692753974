import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

const Portfolio = () => {
  const [isOpen, setOpen] = useState(false);
  const [isOpen2, setOpen2] = useState(false);
  return (
    <SimpleReactLightbox>
      <div className="portfolio_list">
        <ul className="gallery_zoom">
          

          <li>
            <div className="list_inner video">
              <a
                
                target="_blank"
                rel="noopener noreferrer"
                className="title"
              >
                <h3>Caritas Poznań </h3>
                <span>Szkolenie Social Media</span>
              </a>
              <img
                src="img/portfolio/3.jpg"
                alt=""
                onClick={() => setOpen(true)}
              />
            </div>
          </li>
          {/* End youtube */}
          <li
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
            <div className="list_inner video">
              <a
                href="https://futbola.pl/bilety-i-magazyny/20,potezny-przewodnik-kibica-euro-2020"
                target="_blank"
                rel="noopener noreferrer"
                className="title"
              >
                <h3>Euro Przewodnik 2020</h3>
                <span>DTP Design</span>
              </a>
              <img
                src="img/portfolio/2.jpg"
                alt=""
                
              />
            </div>
          </li>
          {/* End vimeo */}

          
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/Citi_Handlowy"
                >
                  <h3>Citi Handlowy</h3>
                  <span>Twitter Marketing</span>
                </a>

                
                  <img src="img/portfolio/1.jpg" alt="Dribbble Shoot" />
                
              </div>
            </li>
            {/* End image popup */}

            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="300"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/payu/?originalSubdomain=pl"
                >
                  <h3>PayU</h3>
                  <span>Linkedin Marketing</span>
                </a>

                
                  <img src="img/portfolio/4.jpg" alt="Dribbble Shoot" />
                
              </div>
            </li>
            {/* End image popup */}

            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="400"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/zubrowka_polska/"
                >
                  <h3>Grupa CEDC</h3>
                  <span>Meta Marketing</span>
                </a>

                
                  <img src="img/portfolio/5.jpg" alt="Dribbble Shoot" />
                
              </div>
            </li>
            {/* End image popup */}

            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://supuppy.com"
                >
                  <h3>Supuppy</h3>
                  <span>Wordpress Development & Social Media Marketing</span>
                </a>

                
                  <img src="img/portfolio/6.jpg" alt="Facebook Shoot" />
                
              </div>
            </li>
            {/* End image popup */}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  
                >
                  <h3>Ava Trade</h3>
                  <span>Social Media Marketing</span>
                </a>

                
                  <img src="img/portfolio/7.jpg" alt="Facebook Shoot" />
                
              </div>
            </li>
            {/* End image popup */}

            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  
                >
                  <h3>Polska Siatkówka</h3>
                  <span>Social Media Marketing</span>
                </a>

                
                  <img src="img/portfolio/8.jpg" alt="Facebook Shoot" />
                
              </div>
            </li>
            {/* End image popup */}

            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://matterhorndigital.nz/social-media-management/"
                >
                  <h3>Matterhorn Digital</h3>
                  <span>UX Design & Social Media</span>
                </a>

                
                  <img src="img/portfolio/9.jpg" alt="Facebook Shoot" />
                
              </div>
            </li>
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://skvot.pl/course/274-social-media-manager"
                >
                  <h3>Skvot / LABA Polska</h3>
                  <span>Kurs Social Media</span>
                </a>

                
                  <img src="img/portfolio/10.jpg" alt="Facebook Shoot" />
                
              </div>
            </li>
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                 
                >
                  <h3>Bank Zachodni WBK</h3>
                  <span>Social Media Marketing</span>
                </a>

                
                  <img src="img/portfolio/11.jpg" alt="Facebook Shoot" />
                
              </div>
            </li>
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  
                >
                  <h3>PasoXL - Padel Courts</h3>
                  <span>Social Media Marketing</span>
                </a>

                
                  <img src="img/portfolio/12.jpg" alt="Facebook Shoot" />
                
              </div>
            </li>
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                 
                >
                  <h3>Puchimi</h3>
                  <span>Social Media Consulting</span>
                </a>

                
                  <img src="img/portfolio/13.jpg" alt="Facebook Shoot" />
                
              </div>
            </li>
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                
                >
                  <h3>Szczyrk Mountain & Resort</h3>
                  <span>Social Media Marketing</span>
                </a>

                
                  <img src="img/portfolio/14.jpg" alt="Facebook Shoot" />
                
              </div>
            </li>
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://mokotownova.pl/"
                >
                  <h3>Mokotów Nova</h3>
                  <span>Wordpress Development</span>
                </a>

                
                  <img src="img/portfolio/15.jpg" alt="Facebook Shoot" />
                
              </div>
            </li>
            {/* End image popup */}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://leydikconsulting.net"
                >
                  <h3>LeydiK Consulting</h3>
                  <span>Wordpress Development</span>
                </a>

                
                  <img src="img/portfolio/16.jpg" alt="Facebook Shoot" />
                
              </div>
            </li>
            {/* End image popup */}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                 
                >
                  <h3>Maxwell Oil Tools</h3>
                  <span>Linkedin Marketing</span>
                </a>

                
                  <img src="img/portfolio/17.jpg" alt="Facebook Shoot" />
                
              </div>
            </li>
            {/* End image popup */}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="list_inner">
                <a
                  className="title"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://studentsu4u.org"
                >
                  <h3>Students United For Ukraine</h3>
                  <span>Wordpress Development</span>
                </a>

                
                  <img src="img/portfolio/18.jpg" alt="Facebook Shoot" />
                
              </div>
            </li>
            {/* End image popup */}
        </ul>
      </div>
    </SimpleReactLightbox>
  );
};

export default Portfolio;
