import React from "react";
import Skills from "../skills/SkillsAnimation";

const AboutDarkAnimation = () => {
  return (
    <>
      <div className="about_inner">
        <div className="left">
          <img src="img/thumbs/1-1.jpg" alt="" />
          <div
            className="image"
            data-aos="fade-right"
            data-aos-duration="1200"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/about/omnie.jpg"
              })`,
            }}
          ></div>
        </div>
        <div
          className="right"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="50"
        >
          <div className="short">
            <h3>Przemysław Kasiura</h3>
            <h5>
              Pomagam <span className="theme-color">przedsiębiorcom </span>
              oraz <span className="theme-color">przyszłym specjalistom</span>
            </h5>
            <p>
              Od 2015 roku pracuję w marketingu, specjalizując się przede wszystkim w reklamach w 
              mediach społecznościowych, UX designie oraz tworzeniu atrakcyjnych i efektywnych stron internetowych. 
              Ta kompleksowa wiedza pozwala mi zrozumieć bolączki na różnych etapach Customer Journey, 
              celem usprawnienia decyzji zakupowej Twojego klienta.
            </p>
          </div>
          <div className="extra">
            <h3 className="title">Informacje</h3>
            <div className="list">
              <ul>
                <li>
                  <p>
                    <span>Imię:</span> Przemysław
                  </p>
                </li>
                <li>
                  <p>
                    <span>Nazwisko:</span> Kasiura
                  </p>
                </li>
                <li>
                  <p>
                    <span>Miasto:</span> Poznań, Wielkopolskie
                  </p>
                </li>
                <li>
                  <p>
                    <span>Rok urodzenia:</span> 1991
                  </p>
                </li>
                <li>
                  <p>
                    <span>Email:</span> hello(at)przemyslawkasiura.pl
                  </p>
                  </li>
              </ul>
             </div> 
             <h3 className="title">Certyfikat</h3>
            <div className="list">
              <ul>
                <li>
                  <p>
                    <span>Nazwa:</span> 410-101: Certyfikowany ekspert firmy Meta ds. zakupu mediów
                  </p>
                </li>
                <li>
                <div className="badge">
                  <a href="https://www.credly.com/badges/f2b82bc2-49bb-4df4-8083-1fb6b0cf6467/public_url">
                  <img src={"img/about/meta_badge.png"}></img></a>
                </div>
                </li>
              </ul>
             </div>
            {/* End list */}
            {/*<div className="beny_tm_button color-outline">
              <a href="img/about/cv.webp" download>
                <span className="wrapper">
                  <span className="first">Pobierz PDF</span>
                  <span className="second">Pobierz PDF</span>
                </span>
              </a>
          </div>*/}
          </div>
        </div>
      </div>
      <Skills />
    </>
  );
};

export default AboutDarkAnimation;
