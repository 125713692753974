import React from "react";
import { useForm } from "react-hook-form";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.target.reset();
    console.log("Wiadomość wysłana: " + JSON.stringify(data));
  };

  return (
    <>
      
      {/* End contact */}
    </>
  );
};

export default Contact;
