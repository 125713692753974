import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const educationContent = [
  {
    passingYear: "2020-2021",
    degree: "Ph.D in Horriblensess ",
    instituteName: "Harvard University, Cambridge, MA",
  },
  {
    passingYear: "2018-2019",
    degree: "Computer Science",
    instituteName: "Imperialize Technical Institute",
  },
  {
    passingYear: "2016-2018",
    degree: "Graphic Designer",
    instituteName: "Web Graphy, Los Angeles, CA",
  },
];

const skillsContent = [
  {
    name: "Web Design",
    skillPercent: "85",
  },
  {
    name: "Mobile App",
    skillPercent: "55",
  },
  {
    name: "Illustrator",
    skillPercent: "65",
  },
  {
    name: "Photoshop",
    skillPercent: "72",
  },
  {
    name: "Motion Graphy",
    skillPercent: "80",
  },
];

const awardContent = [
  {
    awardYear: "2020",
    degree: "Best Developer",
    instituteName: "University Of Melbourne, NA",
  },
  {
    awardYear: "2019",
    degree: "Best Writter",
    instituteName: "Online Typodev Soluation Ltd.",
  },
  {
    awardYear: "2007",
    degree: "Best Freelancer ",
    instituteName: "Fiver & Upwork Level 2 & Top Rated",
  },
];
const experienceContent = [
  {
    designation: "Sr. Software Tester",
    jobType: "Full Time | Remote",
    year: "2020 - Present",
    compnayName: "Google Inc.",
    descriptions:
      "I design and develop services for customers of all sizes, specializing in creating stylish, modern websites",
    animationDealy: "",
  },
  {
    designation: "Sr. Graphic Designer",
    jobType: "Part Time | Office",
    year: "2018 - 2019",
    compnayName: "Avada Theme.",
    descriptions:
      "I design and develop services for customers of all sizes, specializing in creating stylish, modern websites",
    animationDealy: "200",
  },
  {
    designation: "Cr. Web Developer",
    jobType: "Full Time | InHouse",
    year: "2016 - 2017",
    compnayName: "ib-themes ltd.",
    descriptions:
      "I design and develop services for customers of all sizes, specializing in creating stylish, modern websites",
    animationDealy: "",
  },
  {
    designation: "Jr. Web Developer",
    jobType: "Full Time | Remote",
    year: "2014 - 2015",
    compnayName: "Creative Gigs.",
    descriptions:
      "I design and develop services for customers of all sizes, specializing in creating stylish, modern websites",
    animationDealy: "200",
  },
];

const SkillsAnimation = () => {
  return (
    <>
      
    </>
  );
};

export default SkillsAnimation;
